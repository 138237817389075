<template>
  <div class="col">
    <div>
      <!-- <h1>{{$store.getters["groups/getGroupStudents"][0]}}</h1> -->
    </div>
    <!-- Start Info table  -->
    <div v-if="!$store.getters['groups/load']">
      <Info
        v-if="groupInfo != {}"
        :items="groupInfo"
        :style="{ 'direction: rtl': getLocale == 'ar' }"
      ></Info>
      <!-- End Info table  -->
    </div>
    <!-- :is_local="true" -->
    <server-table
      :count="$store.getters['groups/count']"
      :DataItems="studentsData"
      @Refresh="Refresh"
      @selection="selection"
      v-loading="
        $store.getters['user/usersLoad'] ||
        $store.getters['groups/load'] ||
        $store.getters['courses/load']
      "
      @export="Export"
      :useFilter="false"
    >
      <template #columns>
        <el-table-column
          type="selection"
          width="60"
          prop="selection"
        ></el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <!-- embedded table level 1 -->
            <el-table :data="scope.row.tasks">
              <el-table-column
                prop="id"
                label="#"
                min-width="60"
              ></el-table-column>

              <el-table-column
                prop="title"
                :label="$t('activity')"
                min-width="120"
              ></el-table-column>

              <el-table-column
                prop="created_at"
                :label="$t('date')"
                width="130"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.created_at
                      ? toLocalDatetime(scope.row.created_at, "en")
                      : "_"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="badge_id"
                :label="$t('badge')"
                min-width="120"
              >
                <template slot-scope="scope">
                  <i class="fa fa-shield fa-3x text-warning"></i>
                  <p>{{ labels[scope.row.badge_id - 1] }}</p>
                </template>
              </el-table-column>

              <el-table-column
                prop="grade"
                :label="$t('evaluation')"
                min-width="150"
              >
                <template slot-scope="scope">
                  <el-rate
                    v-model="scope.row.grade"
                    disabled
                    text-color="#ff9900"
                  >
                  </el-rate>
                </template>
              </el-table-column>

              <el-table-column
                prop="status"
                :label="$t('admin_dashboard_type.status')"
                min-width="150"
              >
                <template slot-scope="scope">
                  <el-badge
                    :value="activityStatus(scope.row.status).value"
                    :type="activityStatus(scope.row.status).type"
                  >
                  </el-badge>
                </template>
              </el-table-column>

              <el-table-column prop="" label="_" width="100">
                <template slot-scope="scope">
                  <div class="d-flex justify-content-between">
                    <!-- edit activity  -->
                    <i
                      class="fa fa-edit text-info"
                      style="cursor: pointer"
                      @click="openStudentActivityDialog(scope.row)"
                      v-tooltip.top-center="$t('review_active')"
                    ></i>
                    <!-- delete activity  -->
                    <i
                      v-tooltip.top-center="$t('delete_active')"
                      class="fa fa-trash text-danger"
                      @click="deleteActivityEvaluation(scope.row.id)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- End of embedded table level 1  -->
          </template>
        </el-table-column>

        <el-table-column
          prop="student_name"
          :label="$t('admin_dashboard_type.student_name')"
          min-width="50"
        >
          <template slot-scope="scope">
            <router-link
              class="switch_routing"
              :to="`/groups/group/students/profile/${scope.row.id}`"
            >
              {{ scope.row.student_name }}
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          prop="statistics"
          :label="$t('statistics')"
          min-width="70"
        >
          <template slot-scope="scope">
            <span v-for="(statistic, key) in scope.row.statistics">
              <el-badge
                :value="`${activityStatus(statistic.status).value} : ${
                  statistic.count
                }`"
                :type="activityStatus(statistic.status).type"
                class="mb-3"
              ></el-badge>
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="$t('admin_dashboard_type.status')"
          min-width="80"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              round
              size="small"
              type="danger"
            >
              {{ $t("non-active") }}
            </el-button>
            <el-button
              v-if="scope.row.status == 1"
              round
              size="small"
              type="primary"
            >
              {{ $t("active") }}
            </el-button>
            <el-button
              v-if="scope.row.status == 3"
              round
              size="small"
              type="warning"
            >
              {{ $t("hidden") }}
            </el-button>
          </template>
        </el-table-column>
        <!-- //// Start Exam Type  -->
        <el-table-column
          prop="status"
          :label="$t('admin_dashboard_type.torens_exam')"
          min-width="125"
        >
          <template slot-scope="scope">
            <!-- if user not complete 3 exams  -->
            <el-button
              v-if="scope.row.pre_exam == null && scope.row.pre_exam_count != 3"
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exam/1/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.pre_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect to correction exams -->
            <el-button
              v-if="scope.row.pre_exam_count == 3 && scope.row.pre_exam == null"
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exams/1/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.pre_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect exam result -->
            <el-button
              v-if="scope.row.pre_exam != null && scope.row.pre_exam_count == 3"
              size="small"
              type="button"
              class="el-button btn btn-secondary el-button--default"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/report/${scope.row.id}/1`"
              >
                {{ $t("admin_dashboard_type.pre_exam_result") }}
              </router-link>
            </el-button>
            <!-- disabled when user hasn't result after exam -->
            <el-button
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
              :disabled="scope.row.pre_exam == null"
              v-if="
                scope.row.pre_exam == null && scope.row.last_exam_count == 0
              "
            >
              <span> {{ $t("admin_dashboard_type.post_exam") }}</span>
            </el-button>
            <el-button
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default mr-1"
              v-if="
                scope.row.pre_exam != null && scope.row.last_exam_count != 3
              "
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exam/2/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.post_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect to correction exams -->
            <el-button
              v-if="
                scope.row.last_exam_count == 3 && scope.row.after_exam == null
              "
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default mr-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exams/2/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.post_exam") }}
              </router-link>
            </el-button>
            <!-- result for after exam if user doing all 3 exams and pre exam result -->
            <el-button
              v-if="
                scope.row.after_exam != null && scope.row.last_exam_count == 3
              "
              size="small"
              type="button"
              class="el-button btn btn-secondary el-button--default mr-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/report/${scope.row.id}/2`"
              >
                {{ $t("admin_dashboard_type.post_exam_result") }}
              </router-link>
            </el-button>
          </template>
        </el-table-column>
        <!-- //// End Exam Type  -->
        <!--
        <el-table-column
          prop="pending_count"
          label="المجموعات المعلقة"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-badge :value="scope.row.pending_count" type="warning">
            </el-badge>
          </template>
        </el-table-column>


        <el-table-column
          prop="pending_count"
          label="المجموعات المعلقة"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-badge :value="scope.row.pending_count" type="warning">
            </el-badge>
          </template>
        </el-table-column>


        <el-table-column
          prop="pending_count"
          label="المجموعات المعلقة"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-badge :value="scope.row.pending_count" type="warning">
            </el-badge>
          </template>
        </el-table-column>


        <el-table-column
          prop="pending_count"
          label="المجموعات المعلقة"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-badge :value="scope.row.pending_count" type="warning">
            </el-badge>
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="status" label="الاختبار" min-width="50">
          <template slot-scope="scope"> -->
        <!-- <div v-for="students in this.arrs.groups">
 <h5 v-if="students.id == editedStudentActivity.user_id"> العمر: {{students.age}}</h5>
</div> -->

        <!-- <router-link :to="`/exams/${exam_type_1}/${scope.row.id}`" style="width: 100%">


        <el-button

              round
              size="small"
              type="secondary"
              class="mb-2"
              @click="viewExam(scope.row.id,1,exam_type_1)"
  :disabled="scope.row.Exam==false"
            >
             اختبار أول
            </el-button>

        </router-link>

      <router-link :to="`/exams/${exam_type_2}/${scope.row.id}`" style="width: 100%">

        <el-button

              round
              size="small"
              type="danger"
              class="mb-2"
              @click="viewExam(scope.row.id,2,exam_type_2)"
              :disabled="scope.row.last_Exam==false"
            >
             اختبار اخير
            </el-button>

        </router-link>


          </template>
        </el-table-column> -->

        <el-table-column prop="" label="_">
          <template slot-scope="scope">
            <div class="d-flex">
              <!-- block & unblock -->

              <el-button
                type="primary"
                v-if="scope.row.status != 2"
                @click="blockStudentStatus(scope.row.group_id, scope.row.id)"
              >
                {{ $t("Block") }}
              </el-button>
              <el-button
                type="danger"
                v-else
                @click="
                  changeStudentStatus({
                    student_id: scope.row.id,
                    group_id: scope.row.group_id,
                    status: 1,
                  })
                "
                >{{ $t("Unblock") }}</el-button
              >

              <!-- print certificate  -->
              <!-- pass user id and group id to print certificate  -->
              <el-button
                @click="
                  print_certificate(
                    scope.row.student_name,
                    scope.row.id,
                    scope.row.group_id
                  )
                "
                class="mr-2"
                >{{ $t("Print") }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <button
          @click="send_mail_dialog = true"
          class="border-0 text-dark"
          style="background: transparent"
          :disabled="mail.users.length == 0"
        >
          <el-icon class="el-icon-plus"></el-icon>
          ارسال بريد
        </button>
      </template>
    </server-table>

    <!-- Edit student activity evaluation Dialog  -->
    <el-dialog
      :title="$t('student_activity')"
      width="70%"
      :visible.sync="studentActivityDialog"
      top="4vh"
      :show-close="false"
      :class="dialogClass"
    >
      <el-form
        :model="editedStudentActivity"
        v-loading="$store.getters['courses/load']"
        ref="studentActivityDialogRef"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :lg="24">
            <!-- <img
              :src="`https://justtolearn.com/api/evaluation/content/${editedStudentActivity.id}`"
              :alt="editedStudentActivity.name"
              class="mb-2 unique_img"
              style="max-width: 100%"
              draggable="false"
              id="uniqueImg"
            /> -->
            <img
              :src="handleImgSrc(editedStudentActivity.id)"
              :alt="editedStudentActivity.name"
              class="mb-2 unique_img"
              style="max-width: 100%"
              draggable="false"
              id="uniqueImg"
            />
          </el-col>
        </el-row>
        <!-- <p>{{editedStudentActivity}}</p> -->
        <el-row :gutter="20">
          <!-- message  -->
          <!-- <el-col :lg="12">
            <div v-if="editedStudentActivity.message">
              <p class="h4">الرسالة</p>
              <p class="p-2 bg-light">
                {{ editedStudentActivity.message }}
              </p>
            </div>
            <div v-if="editedStudentActivity.feedback">
              <p class="h4">التغذية الراجعة</p>
              <p class="p-2 bg-light">{{ editedStudentActivity.feedback }}</p>
            </div>
          </el-col> -->
          <!-- Feedback  -->
          <el-col :lg="12" :md="12">
            <!-- message  -->
            <div v-if="editedStudentActivity.message">
              <p class="h4">{{ $t("message") }}</p>
              <p class="p-2 bg-light">
                {{ editedStudentActivity.message }}
              </p>
            </div>
            <!-- Feedback  -->
            <div v-if="editedStudentActivity.feedback">
              <p class="h4">{{ $t("feedback") }}</p>
              <p class="p-2 bg-light">{{ editedStudentActivity.feedback }}</p>
            </div>
          </el-col>
          <el-col :lg="12" :md="12">
            <!-- course name  -->
            <p class="h4">
              {{ $t("the_course_name") }}:
              <router-link :to="`/course/${editedStudentActivity.parent_id}`">
                {{ editedStudentActivity.course }}
              </router-link>
            </p>
            <!-- student name  -->
            <p class="h4">
              {{ $t("admin_dashboard_type.student_name") }} :
              {{ editedStudentActivity.name }}
            </p>
            <!-- activity name  -->
            <p class="h4">
              {{ $t("activity") }} : {{ editedStudentActivity.title }}
            </p>

            <div v-for="(students, index) in this.arrs.groups" :key="index">
              <h5 v-if="students.id == editedStudentActivity.user_id">
                {{ $t("admin_dashboard_type.age") }}: {{ students.age }}
              </h5>
            </div>

            <!-- status  -->
            <div class="text-center">
              <el-badge
                :value="activityStatus(radio_status).value"
                :type="activityStatus(radio_status).type"
                class="mb-3"
              >
              </el-badge>
            </div>
            <el-row :gutter="10" class="mb-3 d-flex align-items-start">
              <el-col :span="12" v-if="hasRole('teacher')">
                <el-form-item prop="status">
                  <el-radio-group v-model="radio_status">
                    <!-- val => status text, i + 1 => is the value since backend & database configuration for status values  -->
                    <el-radio
                      v-for="(val, i) in activityStatusValues"
                      :key="i"
                      :label="Number(i)"
                      class="mb-2"
                      >{{ val }}
                    </el-radio>
                  </el-radio-group>
                  <div v-if="radioError" class="text-center text-danger">
                    {{ $t("admin_dashboard_type.radio_error") }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :md="12" :span="12" class="">
                <div class="text-center">
                  <i class="fa fa-shield fa-5x text-warning"></i>
                  <p class="h4 m-0">
                    {{ labels[editedStudentActivity.badge_id - 1] }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <!-- Feedback id -->
            <el-form-item prop="feedback_id">
              <el-select
                v-model="editedStudentActivity.feedback_id"
                :placeholder="$t('feedback')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in $store.getters[
                    'groups/studentFeedback'
                  ].filter(
                    (el) => el.course_id == editedStudentActivity.course_id
                  )"
                  :key="index"
                  :label="item.body"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- feedback text  -->
            <el-form-item
              v-if="hasRole('teacher')"
              :label="$t('feedback')"
              prop="feedback"
            >
              <el-input
                type="textarea"
                v-model="editedStudentActivity.feedback"
              ></el-input>
            </el-form-item>

            <el-checkbox
              v-model="gallery_checked"
              :label="$t('Add to gallery')"
              size="large"
            />
            <!-- rate  -->
            <div class="d-flex justify-content-between activity-rate">
              <el-rate
                :disabled="hasRole('student')"
                v-model="editedStudentActivity.grade"
              >
              </el-rate>
              <i
                class="fa fa-trash fa-4x text-danger mr-3"
                @click="deleteActivityEvaluation(editedStudentActivity.id)"
              ></i>
            </div>
            <el-rate
              class="d-inline-block mt-5 mb-2"
              :disabled="hasRole('student')"
              v-model="editedStudentActivity.badge_id"
              :icon-classes="[
                'fa fa-shield ',
                'fa fa-shield ',
                'fa fa-shield ',
                'fa fa-shield ',
                'fa fa-shield ',
              ]"
              void-icon-class="fa fa-shield "
              :colors="['#00bcd4', '#00bcd4', '#00bcd4', '#00bcd4', '#00bcd4']"
              :texts="labels"
              :show-text="true"
            >
            </el-rate>
          </el-col>
          <el-col
          :md="6"
          >
            <div class="three_d_renders d-flex">
              <router-link
              class="links shadow-sm"
              target="_blank"
              :to="`/img/${editedStudentActivity.id}`"
              >3D Printing</router-link
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="creative_footer_dialog">
        <el-button
          class="text-capitalize cancel_button"
          @click="resetDialog"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-capitalize save_button"
          @click="saveActivityEvaluation"
        >
          {{ $t("save_eval") }}
        </el-button>
      </div>
      <!-- <span
        slot="footer"
        class="dialog-footer flex-column flex-lg-row d-md-flex my-3 mr-auto"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block my-1"
          @click="studentActivityDialog = false"
        >
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize my-1"
          type="primary"
          @click="saveActivityEvaluation"
        >
          {{ $t("save_eval") }}
        </el-button>
      </span> -->
    </el-dialog>

    <!-- send mail Dialog  -->
    <el-dialog
      title="ارسال بريد"
      width="70%"
      :visible.sync="send_mail_dialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        :model="mail"
        ref="mailRef"
        :rules="mailsRules"
        label-position="top"
        v-loading="$store.getters['user/usersLoad']"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="محتوى البريد" prop="content">
              <el-input type="textarea" v-model="mail.content"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="send_mail_dialog = false"
          >الغاء
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="sendMail"
          >ارسال</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";

import Info from "../../components/Cards/Info.vue";

import Swal from "sweetalert2";
import { log } from "three/examples/jsm/nodes/Nodes.js";
export default {
  name: "groupStudent",
  components: { ServerTable, Info },
  data() {
    return {
      gallery_checked: 0,
      exam_type_1: 1,
      exam_type_2: 2,
      group_1: 1,
      group_2: 2,
      group_3: 3,

      groupInfo: {},
      arrs: null,
      // Embedded table level 1
      // excellence
      labels: [
        `${this.$t("no_eval")}`,
        `${this.$t("speed")}`,
        `${this.$t("perfection")}`,
        `${this.$t("quality")}`,
        `${this.$t("excellence")}`,
      ],
      studentActivityDialog: false,
      editedStudentActivity: {},
      radio_status:'',
      activityStatusValues: {
        1: "Accepted",
        2: "Accepted With Modification",
        3: "Resubmit",
      },

      // send email data
      send_mail_dialog: false,
      mail: { users: [], content: "" },
      mailsRules: {
        content: [
          {
            required: true,
            message: "يجب ادخال محتوى البريد",
            trigger: "blur",
          },
        ],
      },
      radioError:false,
    };
  },
  watch: {
    "editedStudentActivity.feedback_id": function (val) {
      let msg = this.$store.getters["groups/studentFeedback"].filter(
        (el) => el.id == val
      )[0];
      this.editedStudentActivity.feedback = msg ? msg.message : " ";
    },
    "editedStudentActivity.age": function (val) {
      let msg = this.$store.getters["groups/getGroupStudents"].filter(
        (el) => el.id == val
      )[0];
      this.editedStudentActivity.age = msg ? msg.age : " ";
    },
  },
  computed: {
    studentsData() {
      return this.$store.getters["groups/groupStudents"];
    },
    count() {
      return this.studentsData.length;
    },

    offset() {
      console.log(this.$route.query);
      if (this.$route.query) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    // fetch group data from server
    this.$store
      .dispatch("groups/getGroupInfo", {
        id: this.$route.params.id,
      })
      .then(
        () =>
          (this.groupInfo = Object.entries(
            this.$store.getters["groups/groupInfo"]
          )
            .map(([key, value]) => {
              return { key, value };
            })
            .filter(
              (item) =>
                item.key !== "id" &&
                item.key !== "course_id" &&
                item.key !== "created_at" &&
                item.key !== "updated_at" &&
                item.key !== "search_text" &&
                item.key !== "teacher_id"
            ))
      );
    this.Refresh();
  },
  methods: {
    handleImgSrc(id) {
      return `${process.env.VUE_APP_BASE_URL}evaluation/content/${id}`;
      // ${process.env.VUE_APP_BASE_URL}
    },
    viewExam(id, group_no, exam_type) {
      const examData = {
        id: id,
        group_no: group_no,
        exam_type: exam_type,
      };
      localStorage.setItem("data", JSON.stringify(examData));
    },

    Refresh(
      query = { offset: this.offset, limit: this.limit, order_by: "id" }
    ) {
      // pass group id to get students data
      this.$store
        .dispatch("groups/getGroupStudents", {
          id: this.$route.params.id,
          query,
        })
        .then((res) => {
          this.arrs = res;
        });
    },
    Export(query) {
      this.$store.dispatch("user/export", {
        query: { ...query, group_id: this.$route.params.id },
      });
    },
    selection(selections) {
      // console.log(selections);
      this.mail.users = [...selections].map((el) => el.id);
    },
    // send email functions
    sendMail() {
      this.$refs["mailRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/send_mail", { query: this.mail })
            .then(() => {
              this.send_mail_dialog = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetDialog(){
      this.studentActivityDialog = false;
      this.radioError = false;
      this.radio_status = this.editedStudentActivity.status;
    },
    changeStudentStatus(query) {
      console.log(query);
      Swal.fire({
        text: query.status
          ? "هل تريد حظر هذا المستخدم ؟ "
          : "هل تريد الغاء حظر هذا المستخدم ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "تأكيد",
        cancelButtonText: "الغاء",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/changeStudentGroupStatus", query)
            .then((_) => {
              this.Refresh();
              Swal.fire({
                text: query.status
                  ? "تم حظر هذا المستخدم"
                  : "تم الغاء حظر هذا المستخدم",
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // block student
    blockStudentStatus(id, student_id) {
      Swal.fire({
        text: "هل تريد حظر هذا المستخدم ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "تأكيد",
        cancelButtonText: "الغاء",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/blockStudentInGroupStatus", {
              id: id,
              student_id: student_id,
            })
            .then((_) => {
              this.Refresh();
              Swal.fire({
                text: "تم حظر هذا المستخدم",
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },

    print_certificate(student_name, userId, groupId) {
      this.$store.dispatch("user/exportCert", {
        student_name,
        userId,
        groupId,
      });
    },

    // embedded table level 1 methods
    activityStatus(status) {
      switch (status) {
        case 1:
          return {
            value: "Accepted",
            type: "success",
          };
        case 2:
          return {
            value: "Accepted With Modification",
            type: "warning",
          };
        case 3:
          return {
            value: "Resubmit",
            type: "danger",
          };
        default:
          return {
            value: "Pending",
            type: "warning",
          };
      }
    },

    openStudentActivityDialog(item, query) {
      // "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921";
      let stuId = item.id;
      this.studentActivityDialog = true;
      // setTimeout(() => {
      //   this.editedStudentActivity = item;
      // }, 5000);
      this.editedStudentActivity = item;
      console.log("item",item);
      this.radio_status = item.status;
      //   this.$store.dispatch("groups/getGroupStudents", {
      //   id: this.$route.params.id,
      //   query,
      // }).then((res)=>{
      //   console.log(res)

      // })
    },
    imageExists(image_url) {
      var http = new XMLHttpRequest();

      http.open("HEAD", image_url, false);
      http.send();
      console.log(http);
      console.log(http.status);
      return http.status != 404;
    },
    deleteActivityEvaluation(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("courses/evaluationDelete", id).then((_) => {
            this.Refresh();
            Swal.fire({
              text: `${this.$t("success_deleted")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    saveActivityEvaluation() {
      var check_Gallery = 0;
      this.editedStudentActivity.status = this.radio_status;
      if (this.gallery_checked == true) {
        check_Gallery = 1;
      } else {
        check_Gallery = 0;
      }
      if (!this.radio_status) {
        this.radioError = true; // Assuming you have a data property radioError
        console.log(this.radioError);
        return; // Stop execution if validation fails
      } else {
        // If selected, reset error message or validation flag
        this.radioError = false;
      }
      this.$refs['studentActivityDialogRef'].validate((valid)=>{
        if(valid){
          this.$store
            .dispatch("courses/evaluation", {
              id: this.editedStudentActivity.id,
              query: {
                feedback: this.editedStudentActivity.feedback,
                status: this.editedStudentActivity.status,
                grade: this.editedStudentActivity.grade,
                badge_id: this.editedStudentActivity.badge_id,
                feedback_id: this.editedStudentActivity.feedback_id,
                selected: check_Gallery,
              },
            })
            .then((_) => {
              this.Refresh();
              this.gallery_checked = false;
              this.studentActivityDialog = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

::v-deep .el-icon-arrow-right:before {
  content: "\e6de";
}

::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  /* font-size: 3rem; */
  /* margin-left: 30px; */
  margin-bottom: 10px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* rate icons  */
::v-deep .activity-rate .el-icon-star-on:before {
  font-size: 3rem;
}

::v-deep .activity-rate .el-icon-star-off:before {
  font-size: 3rem;
}

::v-deep .el-rate__icon.fa-shield {
  font-size: 3rem;
  margin-right: 1.2rem;
}

::v-deep .el-rate__text {
  margin-right: 1rem;
  font-size: 2rem;
}
</style>

<style lang="scss" scoped>
.switch_routing_to_exam {
  // background-color: #409eff;
  // padding: 8px 10px;
  color: #fff;
  text-decoration: none;
}
.three_d_renders {
  margin: 50px 0 10px;
  .links {
    border: 1px solid #eee;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 17px;
  }
}
</style>

<style lang="scss" scoped>
.creative_footer_dialog {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  .save_button {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
  }
  .cancel_button {
    color: #fff;
    background-color: #606266;
    border-color: #606266;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.1s;
    font-weight: 500;
    outline: 0;
    min-width: 140px;
  }
}
</style>
